
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { AccountDetailsDisplay } from "@/shared/composables/api/useGetAccountDetails";
import List from "@/shared/components/lists/List.vue";
import AccountDetailsRewardsCard from "@/shared/components/cards/AccountDetailsRewardsCard.vue";

export default defineComponent({
  props: {
    accountDetailsForDisplay: {
      type: Object as PropType<AccountDetailsDisplay>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const t = useI18n().t;
    return {
      t,
    };
  },
  components: {
    AccountDetailsRewardsCard,
    List,
  },
});
