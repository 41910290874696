import gql from "graphql-tag";

export const accountDetailsGql = gql`
  query Account($hash: String!) {
    account(hash: $hash) {
      ... on Account {
        id
        hash
        rewardsAddress
        rewardsAmount
        amount

        rewards {
          id
          earnedEpoch
          spendableEpoch
          amount
          pool {
            name
            ticker
            hash
          }
        }

        delegations {
          id
          transaction {
            hash
            amount
          }
          poolChange {
            name
            hash
            ticker
          }
        }

        addresses {
          id
          hash
          amount
        }
      }
      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
