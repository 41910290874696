
import { defineComponent, ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import get from "lodash/get";
import BANCard from "@/shared/components/cards/BANCard.vue";
import AccountDetailsCard from "@/shared/components/cards/AccountDetailsCard.vue";
import GlobalSearchInput from "@/shared/components/forms/GlobalSearchInput.vue";
import PageHeaderTitle from "@/shared/components/typography/PageHeaderTitle.vue";
import TabContainer, {
  TabItem,
} from "@/shared/components/layouts/TabContainer.vue";
import { useGetAccountDetails } from "@/shared/composables/api/useGetAccountDetails";
import AccountDetailsAddressTab from "@/shared/components/tabs/AccountDetailsAddressTab.vue";
import AccountDetailsRewardsTab from "@/shared/components/tabs/AccountDetailsRewardsTab.vue";
import AccountDetailsDelegationsTab from "@/shared/components/tabs/AccountDetailsDelegationsTab.vue";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const route = useRoute();
    const hash = computed(() => route.params.hash);

    const { mappedDataForDisplay: accountDetailsForDisplay, isLoading } =
      useGetAccountDetails(hash.value);

    const cardDetails = computed(() => {
      return [
        {
          id: 1,
          cardTitle: t("Total Amount"),
          cardAmount: accountDetailsForDisplay?.value?.totalAmount,
        },
        {
          id: 2,
          cardTitle: t("Total Rewards"),
          cardAmount: accountDetailsForDisplay?.value?.totalRewards,
          minPrecision: 6,
        },
      ];
    });

    const FILTER_LABELS_MAP = {
      addresses: t("Addresses"),
      rewards: t("Rewards history"),
      delegations: t("Delegations history"),
    };

    const tabItems = ref<TabItem[]>([]);

    watch(
      accountDetailsForDisplay,
      (newAccountDetails) => {
        tabItems.value = Object.entries(FILTER_LABELS_MAP).map(
          ([key, value]) => {
            const list = get(newAccountDetails, key, []);
            const itemCount = list.length;
            return {
              key,
              // Show title with filter item count
              title: value + ` (${itemCount})`,
              disabled: itemCount == 0,
            };
          }
        );
      },
      { immediate: true }
    );

    return {
      t,
      accountDetailsForDisplay,
      cardDetails,
      tabItems,
      isLoading,
    };
  },
  components: {
    AccountDetailsDelegationsTab,
    AccountDetailsRewardsTab,
    AccountDetailsAddressTab,
    BANCard,
    AccountDetailsCard,
    GlobalSearchInput,
    PageHeaderTitle,
    TabContainer,
  },
});
