
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import BlockCardLayoutBoxedItem from "@/shared/components/cards/BlockCardLayoutBoxedItem.vue";
import { AccountDetailsDisplay } from "@/shared/composables/api/useGetAccountDetails";
import List from "@/shared/components/lists/List.vue";
import AmountText from "@/shared/components/typography/AmountText.vue";
import { convertAmountToAda } from "@/shared/utils/numbers";
import FullWrapContainer from "@/shared/components/cards/FullWrapContainer.vue";

export default defineComponent({
  props: {
    accountDetailsForDisplay: {
      type: Object as PropType<AccountDetailsDisplay>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const t = useI18n().t;
    return {
      t,
    };
  },
  components: {
    FullWrapContainer,
    AmountText,
    BlockCardLayoutBoxedItem,
    List,
  },
  methods: {
    convertAmountToAda,
  },
});
