import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-100 mb-32 mt-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountDetailsRewardsCard = _resolveComponent("AccountDetailsRewardsCard")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_List = _resolveComponent("List")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { class: "ant-row ant-row-center" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_List, {
          "total-items": _ctx.accountDetailsForDisplay?.rewards?.length,
          "data-source": _ctx.accountDetailsForDisplay?.rewards,
          "show-load-more": false,
          "is-loading": _ctx.isLoading
        }, {
          renderItem: _withCtx(({ item }) => [
            _createVNode(_component_a_list_item, { class: "pb-4 pt-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_AccountDetailsRewardsCard, { accountRewardsDetails: item }, null, 8, ["accountRewardsDetails"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["total-items", "data-source", "is-loading"])
      ])
    ]),
    _: 1
  }))
}