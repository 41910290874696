import { computed, ref, watch, onUnmounted } from "vue";
import get from "lodash/get";
import { useQuery } from "@vue/apollo-composable";
import {
  Account,
  Account_account_Account,
  AccountVariables,
} from "@/api/accounts/__generated__/Account";
import { accountDetailsGql } from "@/api/accounts/accountDetails";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { formatPoolLabel } from "@/shared/utils/stringHelpers";
import { convertAmountToAda } from "@/shared/utils/numbers";

export type AccountDetailsDisplay = ReturnType<
  typeof accountDetailsDisplayMapper
>;

const accountDetailsDisplayMapper = (item: Account_account_Account | null) => {
  if (!item) return null;
  const delegationFirst = get(item?.delegations, 0, null);

  return {
    hash: item?.hash,
    rewardsAddress: item?.rewardsAddress,
    delegation: formatPoolLabel(
      delegationFirst?.poolChange?.name,
      delegationFirst?.poolChange?.ticker,
      delegationFirst?.poolChange?.hash
    ),
    totalAmount: convertAmountToAda(item?.amount),
    totalRewards: convertAmountToAda(item?.rewardsAmount),
    rewards: (item?.rewards || []).map((rewardItem) => ({
      id: rewardItem?.id,
      epochNumber: rewardItem?.earnedEpoch,
      amount: convertAmountToAda(rewardItem?.amount),
      pool: formatPoolLabel(
        rewardItem?.pool?.name,
        rewardItem?.pool?.ticker,
        rewardItem?.pool?.hash
      ),
      date: null,
    })),
    delegations: item?.delegations || [],
    addresses: item?.addresses || [],
  };
};

export const useGetAccountDetails = (hash) => {
  const accountDetailsDisplay =
    ref<ReturnType<typeof accountDetailsDisplayMapper>>();
  const accountDetails = useQuery<Account, AccountVariables>(
    accountDetailsGql,
    () => ({
      hash,
    })
  );

  const parsedGqlAccountDetails = computed(() =>
    parseGqlResponse<Account_account_Account>(
      "Account",
      accountDetails?.result?.value
    )
  );

  watch(parsedGqlAccountDetails, (newParsedGqlAccountDetails) => {
    if (!newParsedGqlAccountDetails.data) return;

    accountDetailsDisplay.value = accountDetailsDisplayMapper(
      newParsedGqlAccountDetails.data
    );
  });

  const timerId = window.setInterval(() => {
    accountDetails.refetch({
      hash,
    });
  }, 5000);

  onUnmounted(() => {
    if (timerId) {
      window.clearInterval(timerId);
    }
  });

  return {
    mappedDataForDisplay: accountDetailsDisplay,
    isLoading: accountDetails?.loading,
  };
};
