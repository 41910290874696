
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
// Mock Types, update when API is available
import { AccountDetailsCard } from "@/shared/types/cards";
import BlockCardLayoutBoxedItem from "@/shared/components/cards/BlockCardLayoutBoxedItem.vue";
import BlockCardLayout from "@/shared/components/cards/BlockCardLayout.vue";
import FullWrapContainer from "@/shared/components/cards/FullWrapContainer.vue";

export default defineComponent({
  props: {
    accountDetails: {
      type: Object as PropType<AccountDetailsCard>,
    },
    loading: {
      type: Boolean,
    },
  },
  setup() {
    const t = useI18n().t;
    return {
      t,
    };
  },
  components: {
    FullWrapContainer,
    BlockCardLayoutBoxedItem,
    BlockCardLayout,
  },
});
